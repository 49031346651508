import { styled } from 'styled-components';

const TitleText = ({
  title,
  subtitle,
  center,
}: {
  title: string;
  subtitle?: string;
  center?: boolean;
}) => {
  return (
    <TitleTextContainer>
      <H1 $center={center}>
        {title}
        {subtitle && (
          <>
            <br />
            <span>{subtitle}</span>
          </>
        )}
      </H1>
    </TitleTextContainer>
  );
};

export default TitleText;

const H1 = styled.h1<{ $center?: boolean }>`
  color: #1b4689;
  display: block;
  font-size: 29px;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
  font-family: Gotham, sans-serif;
  line-height: 1.1;
  text-align: ${(props) => (props.$center ? 'center' : 'left')};

  span {
    color: rgba(35, 38, 63, 0.7);
    font-size: 0.75em;
  }
`;

const TitleTextContainer = styled.div`
  display: grid;
  align-content: center;
  width: 90%;
  max-width: 630px;
`;
