import { useTranslation } from 'react-i18next';

import SuccessIcon from '../components/icons/SuccessIcon';
import PageContainer from '../components/PageContainer';
import TitleText from '../components/TitleText';

/**
 * The `Submitted` function is a React component that renders a success icon and a title text
 * indicating that a form has been submitted.
 * @returns a JSX element. It is rendering a `PageContainer` component that contains a `SuccessIcon`
 * component and a `TitleText` component. The `TitleText` component is receiving a `title` prop that is
 * being translated using the `t` function from the `useTranslation` hook.
 */
export default function Submitted() {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <SuccessIcon />
      <TitleText title={t('formSubmitted')} center />
    </PageContainer>
  );
}
