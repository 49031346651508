import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageContainer from '../components/PageContainer';
import SPOCForm from '../components/SPOCForm/SPOCForm';
import TitleText from '../components/TitleText';

/**
 * The `Index` function is a React component that renders a form and navigates to different pages based
 * on the language selected.
 * @returns a JSX element.
 */
export default function Index() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const onFormSubmitted = () => {
    if (i18n.language === 'cz') navigate('/podnet-odeslan');
    else navigate('/notification-submitted');
  };

  return (
    <PageContainer>
      <TitleText title={t('form.title')} subtitle={t('form.subtitle')} />
      <SPOCForm onFormSubmitted={onFormSubmitted} />
    </PageContainer>
  );
}
