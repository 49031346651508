import Asterix from './Asterix';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { ErrorText } from './ErrorText';
import { Label } from './Label';
import { useTranslation } from 'react-i18next';

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const DropZone = styled.div`
  position: relative;

  padding: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  border: 2px dashed #b4d333;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  text-align: center;
  cursor: pointer;
  min-width: 100%;
  max-width: 100%;

  &:hover {
    border: 2px solid #b4d333;
  }
`;

const FileInput = styled.input`
  position: absolute;

  // -2px to account for parent border
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;

  opacity: 0;
  cursor: pointer;
`;

const FileList = styled.div`
  margin-top: 10px;
`;

const FileItem = styled.div<{ isError?: boolean }>`
  color: #23263f;
  font-size: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  ${(props) =>
    props.isError &&
    css`
      color: #ec5947;
    `}
`;

const DeleteButton = styled.button`
  background: none;
  color: red;
  border: none;
  cursor: pointer;
`;

interface Props {
  label: string;
  name: string;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDelete: (index: number) => void;
  filesSelected: File[];
  required: boolean;
  noFilesError: string;
  invalidFiles: string[];
  overSizeLimitError: boolean;
}

const FileUploadField: React.FC<Props> = ({
  label,
  name,
  onFileChange,
  handleDelete,
  filesSelected,
  required,
  noFilesError,
  invalidFiles,
  overSizeLimitError,
}) => {
  const { t } = useTranslation();

  const fileInput = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFileChange(event);
  };

  const trimFileName = (fileName: string) => {
    if (fileName.length > 22) {
      return fileName.substr(0, 11) + '...' + fileName.substr(-11);
    }

    return fileName;
  };

  const acceptedFormats = process.env.REACT_APP_ACCEPTED_FORMATS ?? '*';

  return (
    <FileUploadContainer>
      <Label htmlFor={name}>
        {label} {required ? <Asterix /> : ''}
      </Label>
      <DropZone>
        <FileInput
          type='file'
          id={name}
          name={name}
          ref={fileInput}
          onChange={handleFileChange}
          multiple
          accept={acceptedFormats}
        />
        {t('form.dragNdrop')}
      </DropZone>

      <FileList>
        {filesSelected.map((file, index) => (
          <FileItem key={file.name} isError={invalidFiles.includes(file.name)}>
            {trimFileName(file.name)}
            <DeleteButton onClick={() => handleDelete(index)}>
              {t('form.delete')}
            </DeleteButton>
          </FileItem>
        ))}
      </FileList>
      {noFilesError && <ErrorText>{noFilesError}</ErrorText>}
      {overSizeLimitError && (
        <ErrorText>{t('form.errors.overFileSizeLimit')}</ErrorText>
      )}
      {invalidFiles.length > 0 && (
        <ErrorText>{t('form.errors.unsupportedFiles')}</ErrorText>
      )}
    </FileUploadContainer>
  );
};

export default FileUploadField;
