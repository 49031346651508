// TextField.tsx
import React from 'react';
import styled, { css } from 'styled-components';

import Asterix from './Asterix';
import { ErrorText } from './ErrorText';
import { Label } from './Label';

const TextFieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;
`;

const Input = styled.input<{ isError?: boolean }>`
  border: 1px solid #c6d5e6;
  transition: border-color 0.3s;
  background-color: #fff;
  color: #333;
  width: 100%;
  line-height: 1.2;
  padding: 10px;

  @media only screen {
    border-width: 2px;
  }

  @media (min-width: 1680px) {
    font-size: 16px;
  }

  ${(props) =>
    props.isError &&
    css`
      border-color: rgba(236, 89, 71, 0.6);
      border-width: 2px;
    `}

  &:focus {
    outline: none;
    border-color: #00478e;
  }
`;

const Description = styled.p`
  margin: 10px 0 0;

  color: rgba(35, 38, 63, 0.7);
  font-size: 13px;
  letter-spacing: -0.5px;
`;

interface Props {
  label: string;
  name: string;
  value: string;
  onChange: (input: string) => void;
  required: boolean;
  error: string;
  description?: string;
}

const TextField: React.FC<Props> = ({
  label,
  name,
  value,
  onChange,
  required,
  error,
  description,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  };

  return (
    <TextFieldContainer>
      <Label htmlFor={name}>
        {label} {required ? <Asterix /> : ''}
      </Label>
      <Input
        type='text'
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        isError={error !== '' && value === ''}
      />
      {description && <Description>{description}</Description>}
      {error !== '' && value === '' && <ErrorText>{error}</ErrorText>}
    </TextFieldContainer>
  );
};

export default TextField;
