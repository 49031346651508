// TextField.tsx
import React from 'react';
import styled, { css } from 'styled-components';

import Asterix from './Asterix';
import { ErrorText } from './ErrorText';
import { Label } from './Label';

const TextFieldContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
`;

const TextArea = styled.textarea<{ isError?: boolean }>`
  max-height: 800px;
  min-width: 100%;
  max-width: 100%;
  min-height: 122px;

  border: 1px solid #c6d5e6;
  transition: border-color 0.3s;
  background-color: #fff;
  color: #333;
  width: 100%;
  line-height: 1.2;
  padding: 10px;

  @media only screen {
    border-width: 2px;
  }

  @media (min-width: 1680px) {
    font-size: 16px;
  }

  ${(props) =>
    props.isError &&
    css`
      border-color: rgba(236, 89, 71, 0.6);
      border-width: 2px;
    `}

  @media (min-width: 400px) {
    min-height: calc(9.4736842105vw + 84.1052631579px);
    font-size: calc(0.15625vw + 13.375px);
  }

  &:focus {
    outline: none;
    border-color: #00478e;
  }
`;

interface Props {
  label: string;
  name: string;
  value: string;
  onChange: (input: string) => void;
  required: boolean;
  error: string;
}

const TextAreaField: React.FC<Props> = ({
  label,
  name,
  value,
  onChange,
  required,
  error,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event.target.value);
  };

  return (
    <TextFieldContainer>
      <Label htmlFor={name}>
        {label} {required ? <Asterix /> : ''}
      </Label>
      <TextArea
        id={name}
        name={name}
        value={value}
        onChange={handleChange}
        autoComplete='on'
        isError={error !== '' && value === ''}
      />
      {error !== '' && value === '' && <ErrorText>{error}</ErrorText>}
    </TextFieldContainer>
  );
};

export default TextAreaField;
