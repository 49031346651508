import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationCzech from './cz/translation.json';
import translationEnglish from './en/translation.json';

const resources = {
  cz: {
    translation: translationCzech,
  },
  en: {
    translation: translationEnglish,
  },
};

i18next.use(initReactI18next).init({ resources, lng: 'cz' });

export default i18next;
